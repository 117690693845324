import React, {Component} from 'react';
import styles from "./GameTable.module.css";

class MaxxFeed extends Component {
    player = null;

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        var config = {
            "source": {
                "defaults": {
                    "service": "bintu"
                },
                "entries": [
                    {
                        "index": 0,
                        "h5live": {
                            "rtmp": {
                                "streamname": "Fge2w-rS7cc"
                            }
                        }
                    }
                ]
            },
            "playback": {
                "autoplay": true,
                "automute": false,
                "muted": false
            },
            "style": {
                "controls": true,
                "width": '100%',
                "height": '100%'
            }
        };
        // eslint-disable-next-line no-undef
        this.player = new NanoPlayer("playerDiv");
        let self = this;
        this.player.setup(config).then(function (config) {
            self.player.play();
            console.log("video streaming loaded");
        }, function (error) {
            alert(error.message);
        });


    }
    render() {
        return (
            <div className={styles.FeedScreen}>
                <div id="playerDiv" className={styles.MonitorVideo}></div>
            </div>
        );
    }
}

export default MaxxFeed;